<template>
  <v-container>
    <v-row>
      <v-col cols="12">
      <v-data-table
        id="list-table"
        ref="datatable"
        :headers="headers"
        :items="contacts"
        :loading="loading"
        item-key="index"
        :hide-default-footer="true"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.item.contactType}}</td>
            <td>{{ props.item.firstName}}</td>
            <td>{{ props.item.familyName}}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.homePhone }}</td>
            <td>{{ props.item.mobilePhone }}</td>
            <td>{{ props.item.workPhone }}</td>
            
          </tr>
        </template>
      </v-data-table>
      </v-col>
    </v-row>
  
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "contact-list",

  data: () => ({
    dialog: false,
    vm: {},
    contacts: [],
    form: {},
    menu: false,
    valid: true,
    totalItems: 0,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Contact Type", value: "contactType" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "familyName" },
      { text: "Email", value: "email" },
      { text: "Home Phone", value: "homePhone" },
      { text: "Mobile Phone", value: "mobilePhone" },
      { text: "Work Phone", value: "workPhone" }
    ],
   
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm = {};
      this.contacts =
        this.currentCase.contacts === undefined
          ? []
          : this.currentCase.contacts;
    },


    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

